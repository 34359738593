<template>
  <div class="querycommunity">
    <LiefengContent>
      <template v-slot:title>{{ `查询社区房号、管理员数` }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <FormItem label="地区选择" :label-width="75">
            <!-- resetNum -->
            <LiefengCascaderCity @changeCasader="changeCasader" :orgCode="44" :width="200" />
          </FormItem>
          <Button type="primary" icon="ios-search" @click="request" style="margin-right: 10px">查询</Button>
          <Button type="primary" @click="daochu" style="margin-right: 10px">导出</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="columns"
          :tableData="data" 
          :hidePage="true">
        </LiefengTable>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengTable from "@/components/LiefengTable"

export default {
  components: {
    LiefengContent,
    LiefengCascaderCity,
    LiefengTable
  },
  destroyed() {
    this.data = null
  },
  data() {
    return {
      arr: [],
      columns: [
        {title: '区', key: 'zoneName'},
        {title: '街道', key: 'streetName'},
        {title: '社区', key: 'communityName'},
        {title: '社区架构数量', key: 'houseNum'},
        {title: '管理员数量', key: 'staffAmount'},
        {title: '动员组织成员数量', key: 'mobilizationNum'},
        {title: '入驻网上家园户数', key: 'onlineFamily'},
        {title: '入驻网上家园人数', key: 'onlinePeople'},
        {title: '底册导入数量', key: 'originalNum'},
        {title: '沟通组数量', key: 'workChatNum'},
        {title: '网格数量', key: 'gridNum'},
        {title: '楼组数量', key: 'buildingNum'},
      ],
      data: []
    }
  },
  methods: {
    request() {
      if(this.arr.length == 0) {
        this.$Notice.error({
          title: '请选择地区范围',
        });
        return
      }
      this.$get("/gateway/api/symanage/pc/orgManageOverall/queryCommunityList",{
        orgCode: this.arr[this.arr.length - 1]
      }).then(res => {
        for(let i = 0;  i < res.dataList.length; i++) {
          if(res.dataList[i].houseNum == null) {
            res.dataList[i].houseNum = 0
          }

          if(res.dataList[i].mobilizationNum == null) {
            res.dataList[i].mobilizationNum = 0
          }

          if(res.dataList[i].staffAmount == null) {
            res.dataList[i].staffAmount = 0
          }
          if(res.dataList[i].onlineFamily == null) {
            res.dataList[i].onlineFamily = 0
          }
          if(res.dataList[i].onlinePeople == null) {
            res.dataList[i].onlinePeople = 0
          }
          if(res.dataList[i].originalNum == null) {
            res.dataList[i].originalNum = 0
          }
        }
        this.data = res.dataList
      })
    },
    changeCasader(arr) {
      this.arr = arr
    },
    // 导出
    daochu() {
      if (this.data.length == 0) {
        this.$Notice.error({
          title: '当前页面数据为空，不能导出',
        });
        return
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>您正在导出社区房号、管理员数数据，是否继续？</p>`,
        onOk: () => {
          this.$Message.loading({
            content: "正在导出数据，请稍等...",
            duration: 0
          });
          let tHeader = [
            "区",
            "街道",
            "社区",
            "社区架构数量",
            "管理员数量",
            "动员组织成员数量",
            "入驻网上家园户数",
            "入驻网上家园人数",
            "底册导入数量",
            "沟通组数量",
            "网格数量",
            "楼组数量"
          ];
          let filterVal = [
            "zoneName",
            "streetName",
            "communityName",
            "houseNum",
            "staffAmount",
            "mobilizationNum",
            "onlineFamily",
            "onlinePeople",
            "originalNum",
            "workChatNum",
            "gridNum",
            "buildingNum"
          ]
          let data = JSON.parse(JSON.stringify(this.data));
          this.$core.exportExcel(tHeader, filterVal, data, "行为数据分析列表");
          this.$Message.destroy();
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
  .querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td{
      height: calc(100vh - 150px);
      border-left: 1px solid #f8f8f9;
      border-bottom: 1px solid #f8f8f9;
      border-right: 1px solid #f8f8f9;
    }
  }
</style>